import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
  inject,
} from "@angular/core";
import { AuthService } from "../auth.service";
import { DOCUMENT } from "@angular/common";
import { SavedsearchService } from "../savedsearch.service";
import {
  DateFacetValue,
  FacetValue,
  FacetValueState,
  ToggleSelectDateFacetValueActionCreatorPayload,
  ToggleSelectFacetValueActionCreatorPayload,
  UpdateDateFacetValuesActionCreatorPayload,
  loadAdvancedSearchQueryActions,
  loadDateFacetSetActions,
  loadFacetSetActions,
  loadQueryActions,
  loadSearchActions,
  loadSearchAnalyticsActions,
} from "@coveo/headless";
import { EngineService } from "../engine.service";
import { Router } from "@angular/router";
import { ResultsPageComponent } from "../results-page/results-page.component";
import { SharedService } from "../shared.service";
import { CryptoService } from "../crypto.service";
// import { CookieService } from "ngx-cookie-service";
import { Customeventlogging } from "../customevent-logging.service";
import { CONFIG } from "src/environments/environment.development";
import { config } from "rxjs";
import { CookieServiceWrapper } from "../cookie-service-wrapper.service";


@Component({
  selector: "app-savedsearch",
  templateUrl: "./savedsearch.component.html",
  styleUrls: ["./savedsearch.component.css"],
})
export class SavedsearchComponent implements OnInit {
  // @ViewChild(SavedsearchComponent) savedSearchComp!: SavedsearchComponent
  descriptionTextPlaceholder:any = CONFIG.SAVED_SEARCHES.DESCRIPTION_TEXT_PLACEHOLDER;
  savedSearches: any;
  private dom: any = inject(DOCUMENT);
  @Input() allSavedSearches: any;
  engine: any;
  @Input() samllPopup = false;
  @Input() bigListPopup = false;
  @Input() SavedSearchAddPopupShow = false;
  @Input() title: any;
  @Input() message: any;
  @Output() close = new EventEmitter();
  @Output() patternNameAdded = new EventEmitter();
  searchPatternName: any;
  mySavedSearch: boolean = false;
  @Output() patternNameAddedNew = new EventEmitter();
  @Input() SavedSearchMsg = false;
  @Input() SavedSearchTextMsg;
  disableNotificationsText:any = CONFIG.SAVED_SEARCHES.DISABLE_NOTIFICATIONS_TEXT;
  noSavedSearches:any = CONFIG.SAVED_SEARCHES.NO_SAVED_SEARCHES;

  noRecordsMsg = "";
  @Input() sortOrder:any;

  showSearches() {
    this.mySavedSearch = true;
    this.loadSearches();
    this.samllPopup = true;
  }

  description:any;
  descriptionValidationMessage:any;
  color:any= "black";
  maxLength =CONFIG.SAVED_SEARCHES.MAX_LENGTH;

  onInputChange(event:any){
        // console.log(this.description.length); 
       
        // console.log(event.target);
            if(this.description.length ==this.maxLength){
                 this.color = "red";
             // this.descriptionValidationMessage = `Character Limit Exceeded. Maximum Characters Allowed: ${this.maxLength}`
              this.descriptionValidationMessage =`You may only enter up to ${this.maxLength} characters. Maximum number of characters reached.`
            }
            else{
              this.descriptionValidationMessage = `${this.maxLength -this.description.length } characters remaining`;
              this.color= "black";
            }
                
  }
  constructor(
    private Auth: AuthService,
    private savedSearchService: SavedsearchService,
    private engineService: EngineService,
    private router: Router,
    private sharedService: SharedService,
    private ngZone: NgZone,
    private cd: ChangeDetectorRef,
    private cryptoService: CryptoService,
    private cookieService: CookieServiceWrapper,
    private eventLoggingService:Customeventlogging
  ) {
    this.engine = engineService.getEngibeObj();
  }

  disableElement(id) {
    this.dom.getElementById(id).disabled = true;
    this.dom.getElementById(id).style.cursor = "not-allowed";
    //document.getElementById(id).style.backgroundColor="grey";
    this.dom.getElementById(id).style.background =
      "linear-gradient(to right, grey 50%, grey 50%)";
  }

  enableElement(id) {
    this.dom.getElementById(id).disabled = false;
    //document.getElementById(id).style.cursor="pointer";
    //document.getElementById(id).style.background="linear-gradient(to right, #045F5F 50%,teal 20%)";
    //document.getElementById(id).style.background="linear-gradient(to right, grey 50%, grey 50%)";
    //document.getElementById(id).style.background= "";
    this.dom.getElementById(id).removeAttribute("style");
  }

  searchTermBind: any;
  finalBindSearchTerm: any;

  ngOnInit(): void {
    let cookiedata = this.cookieService.get("searchTerm");
    // console.log("cookiedata0", cookiedata);
    if (cookiedata != "") {
      let searchTerm = this.cookieService.get("searchTerm");
      // const JsonObj = JSON.parse(searchTerm);
      // this.finalBindSearchTerm = JsonObj.q;
      this.finalBindSearchTerm = searchTerm;
      // console.log("SearchTerm", this.finalBindSearchTerm);
    }

    // Find the index of the "q" key

    // if(history.stat e.savedsearchtab){
    //   this.loadSpecificSearch(search);
    // }
    // history.replaceState("pattern","");
    this.loadSearches();
    // if (this.bigListPopup) {
      this.loadAllSearchesServer();
    // }
  }

  showMsg(flag: any, msg: any) {
    this.SavedSearchMsg = flag;
    this.message = msg;
  }

  loadAllSearches() {
    this.router.navigateByUrl("/settings?tab=3", {
      state: {},
    });

    // var useridDecrypted = this.Auth.getUserId()
    // var tab = 'VVMC'
    // var data = `{
    //   "userISID": "",
    //   "userId":`+useridDecrypted+`,
    //   "source":"ALL",
    //   "searchId":0
    // }`

    // this.savedSearchService.getAllPatterns(data).subscribe(searches => {
    //   this.allSavedSearches = searches.searchPatterns
    // })
  }

  loadingIndicater =false;
  loadAllSearchesServer() {
    this.loadingIndicater = true;
    var useridDecrypted = this.Auth.getUserId();
    var tab = "VVMC";
    var data =
      `{
      "userISID": "",
      "userId":` +
      useridDecrypted +
      `,
      "source":"ALL",
      "searchId":0
    }`;

    this.savedSearchService.getAllPatterns(data).subscribe((searches) => {
      this.allSavedSearches = searches.searchPatterns;
      // this.allSavedSearches.sort((a,b) => (a.patternName.toLowerCase() > b.patternName.toLowerCase()) ? 1 : ((b.patternName.toLowerCase() > a.patternName.toLowerCase()) ? -1 : 0))
      // this.allSavedSearches.sort((a,b) => (a.patternName.localeCompare(b.patternName)));
      // console.log(this.allSavedSearches.length);
      if(this.allSavedSearches.length > 0)
      {
        this.noItemAllSavedFlag = false;
        this.allSavedSearches = searches.searchPatterns;
      if(this.sortOrder==='ascending'){
        // this.savedSearchesList.sort((a,b) => (a.patternName.toLowerCase() > b.patternName.toLowerCase()) ? 1 : ((b.patternName.toLowerCase() > a.patternName.toLowerCase()) ? -1 : 0))
        this.allSavedSearches.sort((a,b) => (a.patternName.localeCompare(b.patternName)));
      }else if(this.sortOrder==='descending'){
        // this.savedSearchesList.sort((a,b) => (b.patternName.toLowerCase() > a.patternName.toLowerCase()) ? 1 : ((a.patternName.toLowerCase() > b.patternName.toLowerCase()) ? -1 : 0))
        this.allSavedSearches.sort((a,b) => (b.patternName.localeCompare(a.patternName)));
      }else if(this.sortOrder==='date-ascending'){
        this.allSavedSearches.sort((a,b) => new Date(a.lastupdateddate).getTime() - new Date(b.lastupdateddate).getTime())
      }else if(this.sortOrder==='date-descending'){
        this.allSavedSearches.sort((a,b) => new Date(b.lastupdateddate).getTime() - new Date(a.lastupdateddate).getTime())
      }
      }
      else
      {
        this.noItemAllSavedFlag = true;
      }
      // console.log("flag", this.noItemAllSavedFlag);
    });
    this.loadingIndicater = false;

    // alert("allSavedSearches-->"+this.sortOrder);
  }


  getDateFormated(datestring){
    return Date.parse(datestring);
  }

  
  noItemAllSavedFlag : boolean = false;
  loadSearches() {
    var useridDecrypted = this.Auth.getUserId();
    var tab = "VVMC";
    var data =
      `{
      "userISID": "",
      "userId":` +
      useridDecrypted +
      `,
      "source":"ALL",
      "searchId":0
    }`;

    this.savedSearchService.getAllPatterns(data).subscribe((searches) => {
      let data = searches.searchPatterns.slice(0, 5);
      this.savedSearches = data;
      if(this.savedSearches.length > 0)
      {
this.noItemFlag = false;
      }
      else {
        this.noItemFlag = true;
      }
      // console.log("item FLag", this.noItemFlag);
      // console.log("item savedSearches", this.savedSearches.length);

      if (!this.savedSearches) {
        this.noRecordsMsg = "No records are available.";
      }
      // // console.log("this.savedSearches ---> "+searches);
    });

    //   this.savedSearchService.getAllCollections(data).subscribe((collections)=>{
    //     // this.collections = collections.collectionlist;
    //     this.vvmcCollections = collections.collectionlist.slice(0,2);
    //   }
    // )
  }
noItemFlag : boolean = false;
  clearAll() {

    var facetState = this.engine.state.facetSet;
    var facetStateDate = this.engine.state.dateFacetSet;
    // console.log("facetState--->"+JSON.stringify(facetState));

    for (const k in facetState) {
      // console.log("k--->"+JSON.stringify(facetState[k].request.facetId));
      var toggleSelectFacetValueaction1 = loadFacetSetActions(
      this.engine
    ).deselectAllFacetValues(facetState[k].request.facetId);
    this.engine.dispatch(toggleSelectFacetValueaction1);
    }

    var toggleSelectFacetValueaction2 = loadFacetSetActions(
      this.engine
    ).deselectAllFacetValues("contentapprovaldate_input");
    this.engine.dispatch(toggleSelectFacetValueaction2);


    const action = loadAdvancedSearchQueryActions(this.engine).updateAdvancedSearchQueries({aq: '',});
    this.engine.dispatch(action);

    const actionDf = loadAdvancedSearchQueryActions(this.engine).updateAdvancedSearchQueries({dq: '',});
    this.engine.dispatch(actionDf);

    const actionlq = loadAdvancedSearchQueryActions(this.engine).updateAdvancedSearchQueries({lq: '',});
    this.engine.dispatch(actionlq);

    const actioncq = loadAdvancedSearchQueryActions(this.engine).updateAdvancedSearchQueries({cq: '',});
    this.engine.dispatch(actioncq);

    const { updateQuery } = loadQueryActions(this.engine);
    const { executeSearch } = loadSearchActions(this.engine);
    const { logSearchboxSubmit } = loadSearchAnalyticsActions(this.engine);
    this.engine.dispatch(updateQuery({q:''}));
  
    this.engine.dispatch(executeSearch(logSearchboxSubmit()));
    this.engine.executeFirstSearch();

  }

  wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }

  loadSpecificSearchTest(){
    var facetValueState: FacetValueState = "selected";
            // var facetValue: FacetValue = {
            //   value: valueOfFacet,
            //   state: facetValueState,
            //   numberOfResults: 5,
            // };

            var facetValueDate: DateFacetValue = {
              start: '2023/10/03@00:00:00',
              end: '2023/10/18@00:00:00',
              state: facetValueState,
              numberOfResults: 1,
              endInclusive: true
            };

            var modaldate: ToggleSelectDateFacetValueActionCreatorPayload = {
              facetId: "contentapprovaldate_input",
              selection: facetValueDate,
            };
            var toggleSelectdateFacetValueaction = loadDateFacetSetActions(
              this.engine
            ).toggleSelectDateFacetValue(modaldate);
            this.engine.dispatch(toggleSelectdateFacetValueaction);

            const { executeSearch } = loadSearchActions(this.engine);
    const { logSearchboxSubmit } = loadSearchAnalyticsActions(this.engine);
    //     engine.dispatch(updateQuery({q:'Eample'}));
    this.engine.dispatch(executeSearch(logSearchboxSubmit()));
    this.engine.executeFirstSearch();
  }


  loadSpecificSearch(search: any) {
    // const engine = this.engineService.getEngibeObj();
    // const { updateQuery } = loadQueryActions(engine);
    //     const { executeSearch } = loadSearchActions(engine);
    //     const { logSearchboxSubmit } = loadSearchAnalyticsActions(engine);
    //     engine.dispatch(updateQuery({q:'Eample'}));
    //     engine.dispatch(executeSearch(logSearchboxSubmit()));
    // engine.executeFirstSearch();

    this.clearAll();
    this.wait(1000);
    // console.log("search",search)
    var favsearchPattern = search.favsearchPattern;
    // console.log("favsearchPattern-->" + favsearchPattern);
    var cq = JSON.parse(favsearchPattern).cq;
    var aq = JSON.parse(favsearchPattern).aq;
    var query =
      JSON.parse(favsearchPattern).q != undefined
        ? JSON.parse(favsearchPattern).q
        : "";
    var coveoFacets = search.facetsCoveo;

    // console.log("cq-->" + cq);
    // console.log("q-->" + query);
    if (cq) {
      var cqSplitArr = cq.split("AND");
      // console.log("cqSplitArr====>" + cqSplitArr);

      const { updateQuery } = loadQueryActions(this.engine);
      this.engine.dispatch(updateQuery({ q: query }));

      for (let i = 0; i < cqSplitArr.length; i++) {
        var eachFacetGroup = cqSplitArr[i];
        if (eachFacetGroup.includes("OR")) {
          // console.log("eachFacetGroup inside--->" + eachFacetGroup);
          var eachFacetGroupArr = eachFacetGroup.split("OR");
          for (var j = 0; j < eachFacetGroupArr.length; j++) {
            var eachFacet = eachFacetGroupArr[j];
            var facetEachObj = eachFacet.split("==");

            // var valueOfFacet = facetEachObj[1].trim().slice(1).slice(0, -2);
            var valueOfFacet = facetEachObj[1].trim().replace(/[{("'@)}]/g, "");
            // var idOfFacet = facetEachObj[0].trim().slice(2)
            var idOfFacet = facetEachObj[0].trim().replace(/[{("'@)}]/g, "");
            // console.log("facetEachObj-->" + valueOfFacet);
            // console.log("facetEachObj-->" + idOfFacet);

            var facetValueState: FacetValueState = "selected";
            var facetValue: FacetValue = {
              value: valueOfFacet,
              state: facetValueState,
              numberOfResults: 5,
            };
            var modal: ToggleSelectFacetValueActionCreatorPayload = {
              facetId: idOfFacet,
              selection: facetValue,
            };
            var toggleSelectFacetValueaction = loadFacetSetActions(
              this.engine
            ).toggleSelectFacetValue(modal);
            this.engine.dispatch(toggleSelectFacetValueaction);
          }
        } else {
          // console.log("eachFacetGroup inside--->" + eachFacetGroup);
          var facetEachObj = eachFacetGroup.split("==");

          // var valueOfFacet = facetEachObj[1].trim().slice(1).slice(0, -2);
          var valueOfFacet = facetEachObj[1].trim().replace(/[{("'@)}]/g, "");
          // var idOfFacet = facetEachObj[0].trim().slice(2)
          var idOfFacet = facetEachObj[0].trim().replace(/[{("'@)}]/g, "");

          // console.log("facetEachObj-->" + valueOfFacet);
          // console.log("facetEachObj-->" + idOfFacet);

          var facetValueState: FacetValueState = "selected";
          var facetValue: FacetValue = {
            value: valueOfFacet,
            state: facetValueState,
            numberOfResults: 5,
          };
          var modal: ToggleSelectFacetValueActionCreatorPayload = {
            facetId: idOfFacet,
            selection: facetValue,
          };
          var toggleSelectFacetValueaction = loadFacetSetActions(
            this.engine
          ).toggleSelectFacetValue(modal);
          this.engine.dispatch(toggleSelectFacetValueaction);
        }
      }
    } else if (query) {
      const { updateQuery } = loadQueryActions(this.engine);
      this.engine.dispatch(updateQuery({ q: query }));
    }
    
    if(aq){
            // alert("aq-->"+JSON.stringify(aq));
            aq = aq.split("AND");

            // var firstPart = "";
            // var seconfPart = "";

            var firstPart = aq[0];
            var seconfPart = aq[1];

            try{
              firstPart = firstPart.split(">=")[1].trim();
            }catch(err){

            }

            try{
              // alert("seconfPart-->"+seconfPart);
              if(seconfPart.includes(">=")){
                seconfPart = seconfPart.split(">=")[1].trim();
              }else{
                seconfPart = seconfPart.split("<=")[1].trim();
              }
              
            }catch(err){
              // alert("error");
            }
            
            // alert("firstPart-->"+firstPart);
            // alert("seconfPart-->"+seconfPart);

            var facetValueState: FacetValueState = "selected";
            // var facetValue: FacetValue = {
            //   value: valueOfFacet,
            //   state: facetValueState,
            //   numberOfResults: 5,
            // };

            if(!firstPart){

              const today = new Date();
              const yyyy = today.getFullYear();
              let mm = today.getMonth() + 1; // Months start at 0!
              let dd = today.getDate();

              firstPart = yyyy+'/'+ mm + '/' +dd+"@00:00:00";

            }

            if(!seconfPart){
              const today = new Date();
              const yyyy = today.getFullYear();
              let mm = today.getMonth() + 1; // Months start at 0!
              let dd = today.getDate();

              seconfPart =  yyyy+'/'+ mm + '/' +dd+"@00:00:00";
            }

            var facetValueDate: DateFacetValue = {
              start: firstPart,
              end: seconfPart,
              state: facetValueState,
              numberOfResults: 1,
              endInclusive: true
            };

            var modaldate: UpdateDateFacetValuesActionCreatorPayload = {
              facetId: "contentapprovaldate_input",
              values: [facetValueDate],
            };
            var toggleSelectdateFacetValueaction = loadDateFacetSetActions(
              this.engine
            ).updateDateFacetValues(modaldate);
            this.engine.dispatch(toggleSelectdateFacetValueaction);



            // var facetValueState: FacetValueState = "selected";
            // // var facetValue: FacetValue = {
            // //   value: valueOfFacet,
            // //   state: facetValueState,
            // //   numberOfResults: 5,
            // // };

            // var facetValueDate: DateFacetValue = {
            //   start: '2023/10/03@00:00:00',
            //   end: '2023/10/18@00:00:00',
            //   state: facetValueState,
            //   numberOfResults: 1,
            //   endInclusive: true
            // };

            // var modaldate: ToggleSelectDateFacetValueActionCreatorPayload = {
            //   facetId: "@contentapprovaldate_input_range",
            //   selection: facetValueDate,
            // };
            // var toggleSelectdateFacetValueaction = loadDateFacetSetActions(
            //   this.engine
            // ).toggleSelectDateFacetValue(modaldate);
            // this.engine.dispatch(toggleSelectdateFacetValueaction);
    }

    const { executeSearch } = loadSearchActions(this.engine);
    const { logSearchboxSubmit } = loadSearchAnalyticsActions(this.engine);
    //     engine.dispatch(updateQuery({q:'Eample'}));
    this.engine.dispatch(executeSearch(logSearchboxSubmit()));
    this.engine.executeFirstSearch();

    //////////////////////////////////////////////////////
    // for(let i=0; i<cqSplitArr.length; i++){
    //    var indCqPart =  cqSplitArr[i];

    //    if(indCqPart.includes("OR")){
    //     var indCqPartSplitArr = indCqPart.split("OR");
    //    // console.log("indCqPartSplitArr--->"+indCqPartSplitArr);
    //    for(let j=0; j<indCqPartSplitArr.length; j++){
    //      var eachFacetString = indCqPartSplitArr[j];
    //      var eachFacetStringSplitArr = eachFacetString.split("==");

    //     var valueOfFacet = eachFacetStringSplitArr[1];
    //     valueOfFacet = valueOfFacet.slice(0,-1);
    //     var idOfFacet = eachFacetStringSplitArr[0];
    //     idOfFacet = idOfFacet.slice(2);

    //     // console.log("IN OR Facet Id-->"+idOfFacet);
    //      // console.log("In OR Facet Value-->"+valueOfFacet);

    //     var facetValueState : FacetValueState ="selected";
    //     var facetValue: FacetValue = {
    //       value: valueOfFacet,
    //       state: facetValueState,
    //       numberOfResults: 5
    //     };
    //     var modal: ToggleSelectFacetValueActionCreatorPayload = {
    //       facetId: idOfFacet,
    //       selection: facetValue
    //     };
    //     var toggleSelectFacetValueaction = loadFacetSetActions(this.engine).toggleSelectFacetValue(modal);
    //     this.engine.dispatch(toggleSelectFacetValueaction);
    //    }

    //    }else{

    //     var eachFacetString = indCqPart;
    //     var eachFacetStringSplitArr = eachFacetString.split("==");

    //     // console.log('eachFacetStringSplitArr-->'+eachFacetStringSplitArr);

    //      var valueOfFacet = eachFacetStringSplitArr[1];
    //     valueOfFacet = valueOfFacet.slice(0,-1);
    //     var idOfFacet = eachFacetStringSplitArr[0];
    //     idOfFacet = idOfFacet.slice(2);

    //     // console.log("In AND Facet Id-->"+idOfFacet);
    //      // console.log("In AND Facet Value-->"+valueOfFacet);

    //     var facetValueState : FacetValueState ="selected";
    //     var facetValue: FacetValue = {
    //       value: valueOfFacet,
    //       state: facetValueState,
    //       numberOfResults: 5
    //     };
    //     var modal: ToggleSelectFacetValueActionCreatorPayload = {
    //       facetId: idOfFacet,
    //       selection: facetValue
    //     };
    //     var toggleSelectFacetValueaction = loadFacetSetActions(this.engine).toggleSelectFacetValue(modal);
    //     this.engine.dispatch(toggleSelectFacetValueaction);

    //    }

    // }

    //   const { executeSearch } = loadSearchActions(this.engine)
    //   const { logSearchboxSubmit } = loadSearchAnalyticsActions(this.engine)
    //   this.engine.dispatch(executeSearch(logSearchboxSubmit()))
    //   // this.engine.executeFirstSearch();

    // const facetValueState : FacetValueState ="selected";
    //     const facetValue: FacetValue = {
    //       value: 'External Use, Verbal Only',
    //       state: facetValueState,
    //       numberOfResults: 5
    //     };
    //     const modal: ToggleSelectFacetValueActionCreatorPayload = {
    //       facetId: 'contentintendeduse',
    //       selection: facetValue
    //     };
    //     const toggleSelectFacetValueaction = loadFacetSetActions(this.engine).toggleSelectFacetValue(modal);

    //     const facetValueState2 : FacetValueState ="selected";
    //     const facetValue2: FacetValue = {
    //       value: 'Scientific Content',
    //       state: facetValueState2,
    //       numberOfResults: 5
    //     };
    //     const modal2: ToggleSelectFacetValueActionCreatorPayload = {
    //       facetId: 'csn_medical_affairs_asset_type',
    //       selection: facetValue2
    //     };
    //     const toggleSelectFacetValueaction2 = loadFacetSetActions(this.engine).toggleSelectFacetValue(modal2);

    //     this.engine.dispatch(toggleSelectFacetValueaction);
    //     this.engine.dispatch(toggleSelectFacetValueaction2);
    //     // const engine = this.engineService.getEngibeObj();
    //     // const { updateQuery } = loadQueryActions(this.engine);
    // const { executeSearch } = loadSearchActions(this.engine);
    // const { logSearchboxSubmit } = loadSearchAnalyticsActions(this.engine);
    // this.engine.dispatch();
    // this.engine.executeFirstSearch();
    let savedSearchObj = {
      'feature': 'loadSearch'
    }
    this.sharedService.sendSavedSearch(savedSearchObj);
  }

  closePopup() {
    this.message = "";
    this.close.emit(close);
  }

  // savePattern(pattern: any) {
  //   this.enableElement("savePatternId");
  //   this.patternNameAdded.emit(pattern);
  //   // this.close.emit(close);
  //   this.disableElement("savePatternId");
  // }

  savePattern(pattern: any,description:any) {
    // console.log("save pattern called"); 
    // console.log(pattern, description);
    var patternObj= {
      pattern,description
    }
    
    this.enableElement("savePatternId");
    this.patternNameAdded.emit(patternObj);
    // this.close.emit(close);
    this.disableElement("savePatternId");
  }
  renameDescription:any;

  getUpdatedDescription(data){
    //this.maxLength = CONFIG.SAVED_SEARCHES.MAX_LENGTH;
         this.renameDescription = data.target.value;
         
        
            if(this.renameDescription.length ==this.maxLength){
                 this.color = "red";
             // this.descriptionValidationMessage = `Character Limit Exceeded. Max Characters: ${this.maxLength}`
              this.descriptionValidationMessage =`You may only enter up to ${this.maxLength} characters. Maximum number of characters reached.`
            }
            else{
              this.descriptionValidationMessage = ` ${this.maxLength - this.renameDescription.length}  characters remaining`;
              this.color= "black";
            }

  }


  SavedSearchRenamePopupShow = false;
  searchObj: any;
  inLineMsgFlag = false;

  renamePattern(searchPatternName: any) {
     var isPatternNameChanged;
    // console.log("searchPatternName", searchPatternName);
    // console.log("this.selectedSearch.patternName", this.renameValue);
    
       // console.log("this.renameValue", this.renameValue);
       // console.log(this.renameValue == undefined);
       // console.log("this.selectedSearch.patternName", this.selectedSearch.patternName);
       // console.log("searchPatternName", searchPatternName);

      //  if(this.renameValue == undefined || this.selectedSearch.patternName == this.renameValue){
      //     isPatternNameChanged = false;
      //  }
      //  else{
      //   isPatternNameChanged = true;
      //  }
          
       
        if(this.renameDescription == undefined){
          this.renameDescription= this.selectedSearch.description
        }
        if(this.renameValue == undefined){
          this.renameValue= this.selectedSearch.patternName
        }

        // console.log("condition check",this.renameValue == this.selectedSearch.patternName);
        if(this.selectedSearch.patternName == this.renameValue){
          isPatternNameChanged = false;
         }
         else{
          isPatternNameChanged = true;
         }
         // console.log("description", this.renameDescription);
       // console.log(" isPatternNameChanged",isPatternNameChanged);
    this.disableElement("renamePatternId");
    var userId = this.Auth.getUserId();
    var payload = {
      patternName: this.renameValue,
      userId: userId || 2,
      description:this.renameDescription,
      searchpatternId: this.selectedSearch.searchpatternId,
      action: "update",
      isPatternNameChanged : isPatternNameChanged,
      source: this.selectedSearch.source,
    };
      // console.log("payload", payload);
  

    this.savedSearchService.renameSearchToDB(payload).subscribe((searches) => {
      // this.savedSearches = searches.searchPatterns
      // console.log("this.savedSearches ---> " + searches);
      // this.SavedSearchRenamePopupShow = false;
      if (searches == null) {
        // this.SavedSearchMsg = true;
       // this.message = "A saved search with that name already exists. Please enter a different name.";
        this.message =  CONFIG.SAVED_SEARCHES.DUPLICATE_SAVED_SEARCH;
        this.inLineMsgFlag = true;
      } else if (searches.statusCode == 1003) {
        // this.SavedSearchMsg = true;
        this.inLineMsgFlag = true;
        // this.message = searches.statusDescription;
       // this.message ="Your Search has been successfully saved. Notifications will be received when new or updated news links or documents meet the saved search criteria.";
      // this.message =CONFIG.SAVED_SEARCHES.SAVED_SEARCH_SUCCESS;
      this.message = CONFIG.SAVED_SEARCHES.DUPLICATE_SAVED_SEARCH;
      } else if (searches.statusCode == 1000) {
        this.SavedSearchRenamePopupShow = false;
        this.SavedSearchMsg = true;
        // this.message = searches.statusDescription;
       // this.message = "Your Search has been successfully saved. Notifications will be received when new or updated news links or docuemnts meet the saved search criteria.";
       this.message =CONFIG.SAVED_SEARCHES.SAVED_SEARCH_SUCCESS;
      } else {
        this.SavedSearchRenamePopupShow = false;
        this.inLineMsgFlag = true;
        this.SavedSearchMsg = true;
        //this.message = "This search is renamed sucessfully.";
        this.message = CONFIG.SAVED_SEARCHES.SAVED_SEARCH_RENAME_SUCCESS;
      }

      //       this.loadSearches();
      this.loadAllSearchesServer();
      this.enableElement("renamePatternId");
    });

    this.inLineMsgFlag = false;
    this.descriptionValidationMessage = "";
          this.message = "";
          this.renameValue = undefined;
  }

  renameValue: any;
  renameBtnDisable = false;
  getRename(data) {

    if(data.target.value){
      if(data.target.value.charAt(0)==' ' || data.target.value.charAt(0)==' '){
        var input:any = document.getElementById("saveRename") as HTMLElement | null;
        input.value = data.target.value.trim();
        data.preventDefault();
      }
    }
    
    this.renameValue = data.target.value;
    // console.log("this.renameValue", this.renameValue);
    if (!this.renameValue) {
       // console.log("");
      this.renameBtnDisable = true;
    } else {
      this.renameBtnDisable = false;
    }
  }

  keyDownValidator(event){
    // alert(JSON.stringify(this.collectionName));
    // this.collectionName.trimStart();
    if(this.finalBindSearchTerm){
      if(this.finalBindSearchTerm.charAt(0)==' ' || this.finalBindSearchTerm.charAt(0)==' '){
        this.finalBindSearchTerm = this.finalBindSearchTerm.trim();
        event.preventDefault();
      }
    }
  }


  
  SavedSearchSubscribePopupShow = false;

  subscription() {
    if (this.dom.getElementById("subscriptionId") != null) {
      this.disableElement("subscriptionId");
    }

    let payload = {
      subscription: this.selectedSearch.subscribed,
      id: this.selectedSearch.searchpatternId,
      confirmation: true,
    };
    this.savedSearchService.subscribeSearch(payload).subscribe((searches) => {
      // this.savedSearches = searches.searchPatterns
      // console.log("this.savedSearches ---> " + searches.headers);
      this.SavedSearchSubscribePopupShow = false;
      //     this.loadSearches();
      this.loadAllSearchesServer();
      if (this.dom.getElementById("subscriptionId") != null) {
        this.enableElement("subscriptionId");
      }
    });
  }

  renameSearch(search: any) {
    this.SavedSearchRenamePopupShow = true;
    this.searchPatternName = search.patternName;
    this.searchObj = search;
  }
  
  async deleteMultiSearch(){
    this.disableElement("deleteSearchId");
    var userId = this.Auth.getUserId();

    for(var savedSearch of this.selectionList ){
      let payload = {
        patternName: "undefined",
        userId: userId,
        searchpatternId: savedSearch.searchpatternId,
        action: "delete",
        source: savedSearch.source,
        isPatternNameChanged: false,
        description:""
      };
  var response= await this.savedSearchService.renameSearchToDB(payload).toPromise();
  console.log("response", response);
     }
     this.SavedSearchDeletePopupShow = false;
     this.deleteSavedSearchNames = "";
     this.selectionList = [];
     this.loadAllSearchesServer();
      this.enableElement("deleteSearchId");
  }
  deleteSearch() {
    this.disableElement("deleteSearchId");
    var userId = this.Auth.getUserId();

    let payload = {
      patternName: "undefined",
      userId: userId,
      searchpatternId: this.selectedSearch.searchpatternId,
      action: "delete",
      source: this.selectedSearch.source,
      isPatternNameChanged: false,
      description:""
    };
    this.savedSearchService.renameSearchToDB(payload).subscribe((searches) => {
      // this.savedSearches = searches.searchPatterns
      // console.log("this.savedSearches ---> " + searches.headers);
      this.SavedSearchDeletePopupShow = false;
      //     this.loadSearches();
      this.loadAllSearchesServer();
      this.enableElement("deleteSearchId");
    });
  }

  selectionList:any = [];
  // mainCheckBox(){
  //   var mainCheckBoxValue = this.dom.getElementById("mainCheckBox");
  //   console.log(mainCheckBoxValue.checked); 
  //   if(mainCheckBoxValue.checked){
  //    for(let i=0; i<10; i++){
  //       let multiselectValues = this.dom.getElementsByClassName("multiselect");
  //       multiselectValues[i].checked = true;
  //       this.selectionList.push(this.allSavedSearches[i]);
  //    }
  //   }
  //   else{
  //    for(let i=0; i<10; i++){
  //      let multiselectValues = this.dom.getElementsByClassName("multiselect");
  //      multiselectValues[i].checked = false;
      
  //   }
  //   this.selectionList = [];
  //   }
  //   console.log("this.selectionList", this.selectionList);
   
  // }
  maxSelectionReached = false;
  addRemoveToMultiList(search){
    console.log("this.selectionList.length", this.selectionList.length);

  //   var checkifFirstFewItemsSelected = true;
  
  //   for(let i=0; i<10; i++){
  //     let multiselectValues = this.dom.getElementsByClassName("multiselect");
  //     if(multiselectValues[i].checked == false){
  //       checkifFirstFewItemsSelected = false;
  //        break;
  //     }
  //  }
  //  if(checkifFirstFewItemsSelected){
  //     this.dom.getElementById("mainCheckBox").checked = true;
  //  }
  //  else{
  //   this.dom.getElementById("mainCheckBox").checked = false;
  //  }
  
    var selectedItem = this.dom.getElementById(search.searchpatternId);
     if(this.selectionList.length > 9 &&  selectedItem.checked == true){
      selectedItem.checked = false;
            this.maxSelectionReached = true;
            return;
     }
  
  
  
     if(selectedItem.checked){
      this.selectionList.push(search);
      console.log("selectionList",this.selectionList);
     }
     else{
           let index =  this.selectionList.indexOf(search);
           this.selectionList.splice(index,1);
           console.log("selectionList",this.selectionList);
     }
  }

  // share() {
  //   // has invited you to access and explore the information for the saved search
  //   let userFullName = this.cookieService.get("fn");
  //   userFullName = userFullName
  //     ? this.cryptoService.decryptData(userFullName)
  //     : "";
  //   var email = "";
  //   // var subject = "GMSA Content Search Navigator: Asset share";
  //   var subject =CONFIG.SAVED_SEARCHES.SHARE_EMAIL_SUBJECT;
  //   var emailBody = "";
  //   emailBody +=
  //     userFullName +
  //     "  has invited you to access and explore the information for the saved search: " +
  //     encodeURIComponent(this.selectedSearch.patternName) +
  //     "%0D%0A Link:  " +
  //     encodeURIComponent(
  //       `${CONFIG.SAVED_SEARCHES.SHARED_LINK_EMAIL}` +
  //         this.selectedSearch.searchpatternId
  //     );
  //   emailBody += "%0D%0A %0D%0A" + this.disclaimer_label + "%0D%0A";
  //   emailBody += this.disclaimer_txt;
  //   document.location =
  //     "mailto:" + email + "?subject=" + subject + "&body=" + emailBody;
  // }
  share() {
    // has invited you to access and explore the information for the saved search
    let userFullName = this.cookieService.get("fn");
 
  // // console.log("this.selectedDocumentDetails.Title",collection.name);
  var shareLink = CONFIG.SAVED_SEARCHES.EMAIL_SHARE_LINK;
  var disclaimer_label = "";
  var disclaimer_txt = "";
  var email ='';
  var subject = 'GMSA Content Search Navigator: Asset share';
  var emailBody = userFullName +` has invited you to access and explore the saved searche(s):`;	

  //emailBody +=userFullName+" has invited you to access and explore the collections: "+encodeURIComponent(collection.name)+"%0D%0A Link:  "+encodeURIComponent("https://gmsasearch-dev.merck.com/share/collections/"+collection.id+"");
  //var link = linkUrlPart+docID;	
  console.log("this.selectionList.length", this.selectionList.length);
  if(this.selectionList.length>1){
    emailBody +="\n";
    for(let doc of this.selectionList){
      console.log("selected docs", this.selectionList);

      emailBody +="\n";
      var trimmedString = "";
              if(doc.patternName.length > 25){
                trimmedString = `${doc.patternName}`.substring(0, 25)+"...";
              }
              else{
                trimmedString = doc.patternName;
              }

      emailBody += trimmedString;	
      // emailBody +="\n";

     
      emailBody += "\n"+"Link: " + (shareLink+doc.searchpatternId);
      emailBody +="\n";
    }
  }else{
    emailBody += `${this.selectedSearch.patternName} \n`;	
  //  emailBody +=userFullName+" has invited you to access and explore the collections: "+encodeURIComponent(collection.name)+"%0D%0A Link:  "+encodeURIComponent("https://gmsasearch-dev.merck.com/share/collections/"+collection.id+"");
  emailBody += "\n"+"Link: " + (shareLink+this.selectedSearch.searchpatternId);	
  }
  emailBody +="\n";	
  emailBody = encodeURIComponent(emailBody);	
  emailBody +="%0D%0A %0D%0A"+this.disclaimer_label+"%0D%0A";
  emailBody += this.disclaimer_txt;
  document.location = "mailto:"+email+"?subject="+subject+"&body="+emailBody;
  }

  closeRenamePopup() {
    this.SavedSearchRenamePopupShow = false;
    this.SavedSearchSubscribePopupShow = false;
  }

  selectedSearch: any;
  flag: String = "";
  SavedSearchDeletePopupShow: boolean = false;
  // shareLink = "https://gmsasearch-sit.merck.com/index.html";
  // shareLink = "https://gmsasearch-dev.merck.com/index.html";
  shareLink = CONFIG.SAVED_SEARCHES.SHARE_LINK;

  // disclaimer_label = "About GSMA Content Search Navigator:";
  disclaimer_label = CONFIG.SAVED_SEARCHES.DISCLAIMER_LABEL;
  //disclaimer_txt = "The GMSA Content Search Navigator is an advanced search engine tool that provides GMSA colleagues with a simplified approach for efficiently and effectively searching across multiple repositories for scientific and related content.";
  disclaimer_txt = CONFIG.SAVED_SEARCHES.DISCLAIMER_TEXT;

  deleteSavedSearchNames:any = "";

  async savedSearchesFunction(searchData, flag) {
    this.message = "";
    if(sessionStorage.getItem("maintenanceAccess")  == null || sessionStorage.getItem("maintenanceAccess") != "1") {
      var resp = await this.Auth.getMaintenanceStatus({
        "checkForMaintainence":true
      });
      // console.log("resp", resp);
           if(resp == "true"){
            this.router.navigateByUrl("/maintenance");
            return;
           }
          }
    // console.log(searchData);
    // console.log(flag);
    this.flag = flag;
    // this.samllPopup = true;

    if (this.flag == "rename") {
      this.SavedSearchRenamePopupShow = true;
      this.selectedSearch = searchData;
      // console.log("selectedSearch", this.selectedSearch);
    } else if (this.flag == "unsubscribe") {
      this.SavedSearchSubscribePopupShow = true;
      this.selectedSearch = searchData;
      // console.log("selectedSearch", this.selectedSearch);
    } else if (this.flag == "subscribe") {
      this.selectedSearch = searchData;
      // console.log("selectedSearch", this.selectedSearch);
      this.subscription();
    } else if (this.flag == "delete") {
      if(this.selectionList.length > 0){
       
        for(let i=0; i <this.selectionList.length; i++){
          this.deleteSavedSearchNames = this.deleteSavedSearchNames + this.selectionList[i].patternName + ", "; 
        }
       this.deleteSavedSearchNames = this.deleteSavedSearchNames.substring(0,(this.deleteSavedSearchNames.length -2));
        console.log("this.deleteSavedSearchNames", this.deleteSavedSearchNames);
        
      }
      this.SavedSearchDeletePopupShow = true;
      this.selectedSearch = searchData;
      // console.log("selectedSearch", this.selectedSearch);
    } else if (this.flag == "share") {
      this.selectedSearch = searchData;
      // console.log("selectedSearch", this.selectedSearch);
      this.share();
    }
  }

  async loadSpecificSearchInResults(search) {
    if(sessionStorage.getItem("maintenanceAccess")  == null || sessionStorage.getItem("maintenanceAccess") != "1") {
      var resp = await this.Auth.getMaintenanceStatus({
        "checkForMaintainence":true
      });
      // console.log("resp", resp);
           if(resp == "true"){
            this.router.navigateByUrl("/maintenance");
            return;
           }
          }
    this.router.navigateByUrl("/", {
      state: {
        pattern: search,
      },
    });

    // this.sharedService.sendData(JSON.stringify(search));
    // this.router.navigate(['ResultsPageComponent'],{
    //   state:{
    //     pattern:{
    //       'label':'val'
    //     }
    //   }
    // });

    //   var favsearchPattern = search.favsearchPattern
    //   // console.log('favsearchPattern-->' + favsearchPattern)
    //   var cq = JSON.parse(favsearchPattern).cq
    //   var query = JSON.parse(favsearchPattern).q !=undefined?JSON.parse(favsearchPattern).q:"";
    //   var coveoFacets = search.facetsCoveo

    //   // console.log('cq-->' + cq)
    //   // console.log('q-->' + query)
    //   if(cq){
    //   var cqSplitArr = cq.split('AND')
    //   // console.log('cqSplitArr====>' + cqSplitArr)

    //   const { updateQuery } = loadQueryActions(this.engine)
    //   this.engine.dispatch(updateQuery({ q: query }))

    //   for (let i = 0; i < cqSplitArr.length; i++) {
    //     var eachFacetGroup = cqSplitArr[i]
    //     if (eachFacetGroup.includes('OR')) {
    //       // console.log('eachFacetGroup inside--->' + eachFacetGroup)
    //       var eachFacetGroupArr = eachFacetGroup.split('OR')
    //       for (var j = 0; j < eachFacetGroupArr.length; j++) {
    //         var eachFacet = eachFacetGroupArr[j]
    //         var facetEachObj = eachFacet.split('==')

    //         var valueOfFacet = (facetEachObj[1].trim()).replace(/[{("'@)}]/g,'');
    //         var idOfFacet = (facetEachObj[0].trim()).replace(/[{("'@)}]/g,'');
    //         // console.log('facetEachObj-->' +valueOfFacet);
    //         // console.log('facetEachObj-->' + idOfFacet);

    //         var facetValueState: FacetValueState = 'selected'
    //         var facetValue: FacetValue = {
    //           value: valueOfFacet,
    //           state: facetValueState,
    //           numberOfResults: 5
    //         }
    //         var modal: ToggleSelectFacetValueActionCreatorPayload = {
    //           facetId: idOfFacet,
    //           selection: facetValue
    //         }
    //         var toggleSelectFacetValueaction = loadFacetSetActions(
    //           this.engine
    //         ).toggleSelectFacetValue(modal)
    //         this.engine.dispatch(toggleSelectFacetValueaction)
    //       }
    //     } else {
    //       // console.log('eachFacetGroup inside--->' + eachFacetGroup)
    //       var facetEachObj = eachFacetGroup.split('==')

    //       var valueOfFacet = (facetEachObj[1].trim()).replace(/[{("'@)}]/g,'');
    //       var idOfFacet = (facetEachObj[0].trim()).replace(/[{("'@)}]/g,'');

    //       // console.log('facetEachObj-->' + valueOfFacet);
    //       // console.log('facetEachObj-->' + idOfFacet);

    //       var facetValueState: FacetValueState = 'selected'
    //       var facetValue: FacetValue = {
    //         value: valueOfFacet,
    //         state: facetValueState,
    //         numberOfResults: 5
    //       }
    //       var modal: ToggleSelectFacetValueActionCreatorPayload = {
    //         facetId: idOfFacet,
    //         selection: facetValue
    //       }
    //       var toggleSelectFacetValueaction = loadFacetSetActions(
    //         this.engine
    //       ).toggleSelectFacetValue(modal)
    //       this.engine.dispatch(toggleSelectFacetValueaction)
    //     }
    //   }
    // }else if(query){
    //   const { updateQuery } = loadQueryActions(this.engine);
    //   this.engine.dispatch(updateQuery({ q: query }));
    // }

    //   const { executeSearch } = loadSearchActions(this.engine)
    //   const { logSearchboxSubmit } = loadSearchAnalyticsActions(this.engine)
    //   this.engine.dispatch(executeSearch(logSearchboxSubmit()))
    //   this.engine.executeFirstSearch()
  }

  enableBtns(){
    this.enableElement("savePatternId");
  }

  // custom events

  customEventsSpecificSearch(name) {
    this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Click',"Pattern Name" : name},'SavedSearch-Menu');

  }

  customEventsSeeAll()
  {
    this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Click'},'SavedSearch-See-All');

  }


  customEventsSaveSearch(patternName)
  {
    this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Save-Search-Actual', Name : patternName},'Save-Search-Actual');

  }

  customEventSavedSearchRename(patternName)
  {
    this.eventLoggingService.logCustomEvent('Settings Page Save Search', { userId: this.cookieService.get('usi'),Action:'Save-Search-Rename', Details : patternName},'Save-Search-Rename');

  }

  customEventSavedSearchShare(patternName)
  {
    this.eventLoggingService.logCustomEvent('Settings Page Save Search', { userId: this.cookieService.get('usi'),Action:'Save-Search-Share', Details : patternName},'Save-Search-Share');

  }

  customEventSavedSearchDelete(patternName)
  {
    this.eventLoggingService.logCustomEvent('Settings Page Save Search', { userId: this.cookieService.get('usi'),Action:'Save-Search-Delete', Details : patternName},'Save-Search-Delete');

  }

  customEventSavedSearchUnsubscribe(patternName)
  {
    this.eventLoggingService.logCustomEvent('Settings Page Save Search', { userId: this.cookieService.get('usi'),Action:'Save-Search-Unsubscribe', Details : patternName},'Save-Search-Unsubscribe');

  }

  customEventSavedSearchsubscribe(patternName)
  {
    this.eventLoggingService.logCustomEvent('Settings Page Save Search', { userId: this.cookieService.get('usi'),Action:'Save-Search-Subscribe', Details : patternName},'Save-Search-Subscribe');

  }

  customEventSavedSearchSpecific(patternName)
  {
    this.eventLoggingService.logCustomEvent('Settings Page Save Search', { userId: this.cookieService.get('usi'),Action:'Save-Search-Specific', Details : patternName},'Save-Search-Specific');

  }

  
}
