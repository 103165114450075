import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { SharedService } from '../shared.service'
import { CollectionsService } from '../collections.service'
import { AuthService } from '../auth.service'
import { SavedsearchService } from '../savedsearch.service'
import { CookieService } from 'ngx-cookie-service'
import { CONFIG } from 'src/environments/environment.development'
import { ENVIRONMENT } from 'src/environments/environment.development';
import { CookieServiceWrapper } from '../cookie-service-wrapper.service'

@Component({
  selector: 'app-csn-share',
  templateUrl: './csn-share.component.html',
  styleUrls: ['./csn-share.component.css']
})
export class CsnShareComponent implements OnInit {
  feature: any
  id: any
  userId:any;

  constructor (
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private router: Router,
    private collectionService: CollectionsService,
    private authService: AuthService,
    private savedSearchService: SavedsearchService,
    private cookieService:CookieServiceWrapper,
  ) {


    let href = this.router.url;
    // if(!this.cookieService.get("usi")){
      // this.cookieService.set("redirect",href,1,"/");
      this.cookieService.set("redirect",href, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
      cookieService.set("ucon","");
    // }
    // else{
    //   this.cookieService.set("redirect","",1,"/");
    // }

    
    this.feature = this.route.snapshot.paramMap.get('feature')
    this.id = this.route.snapshot.paramMap.get('id')
    this.userId = this.authService.getUserId();
    if(!this.userId){
      return;
    }
    // console.log(
    //   'feature--->' + this.feature + '------------this.id--->' + this.id
    // )
    if (this.feature == 'collections') {
      this.getCollectionsLoad(this.id)
    } else if (this.feature == 'searches') {
      this.getSavedSearchLoad(this.id);
    }

  }

  ngOnInit () {

    
  }

  collectionNotAvailableInShareringFeature = "";
  title="";
  body = "";
  getCollectionsLoad (id: any) {
    var docsOfCollection: any
    var tab = 'ALL'
    var data =
      `{
        "collectionId": `+id +`,
        "userId":"",
        "tab":"` +tab +`"
      }`
    this.collectionService.getAllCollections(data).subscribe(collections => {
      // console.log('collections--->' + JSON.stringify(collections))

      if(!(collections.collectionlist.length>0)){


        this.collectionNotAvailableInShareringFeature = "mycollectionsmessage";
        //this.title = "My Collections";
        this.title = CONFIG.CSNSHARE.COLLECTIONS_TITLE;
        // this.body = "This collection has been deleted by its owner and is no longer available.";
        this.body = CONFIG.CSNSHARE.COLLECTIONS_BODY;



        // let coll = {
        //   'userHasCollection':'false',
        //   'collectionObj': collections.collectionlist[0],
        // }
        // this.router.navigateByUrl('/', {
        //   state: {
        //     collections: coll
        //   }
        // });
        return;
      }

      this.userId = this.authService.getUserId();
      var data =
      `{
        "collectionId": "",
        "userId":`+this.userId+`,
        "tab":"` +tab +`"
      }`

      this.collectionService.getAllCollections(data).subscribe(collectionsOnUserId => {
        if(collectionsOnUserId.collectionlist.length>0){
          let hasObj = false;
          for(let jsonObj of collectionsOnUserId.collectionlist){
            if(jsonObj.id==id){
              hasObj = true;
            }
          }
          if(hasObj){
            let coll = {
              'userHasCollection':'true',
              'collectionObj': collections.collectionlist[0],
            }
            this.router.navigateByUrl('/', {
              state: {
                collections: coll
              }
            })

          }else{
            let coll = {
            'userHasCollection':'false',
            'collectionObj': collections.collectionlist[0],
          }
          this.router.navigateByUrl('/', {
            state: {
              collections: coll
            }
          })

          }
        }else{
          let coll = {
            'userHasCollection':'false',
            'collectionObj': collections.collectionlist[0],
          }
          this.router.navigateByUrl('/', {
            state: {
              collections: coll
            }
          })
          
        }
      });
    })
  }

  emptyCollectionPopupClose(){
    this.collectionNotAvailableInShareringFeature = "none";
    this.router.navigateByUrl('/landing', {
            state: {
              // collections: coll
            }
          })
  }

  getSavedSearchLoad (id: any) {
    var useridDecrypted = this.authService.getUserId()
    var tab = 'VVMC'
    var data =
      `{
      "userISID": "",
      "userId":`+this.userId+`,
      "source":"ALL",
      "searchId":` +
      id +
      `
    }`

    var query = ''
    var searchPattern: any

    this.savedSearchService.getAllPatterns(data).subscribe(res => {

      try{

        // console.log(
        //   'data--->' + JSON.stringify(res.searchPatterns[0].favsearchPattern)
        // )
        searchPattern = res.searchPatterns[0];
        query = res.searchPatterns[0].favsearchPattern
  
        // console.log('searchPattern-->' + JSON.stringify(searchPattern));
        // console.log('searchPattern-->' + query);
  
        // Should come from DB
        // var query = `{"q": "keytruda", "cq": "(@qddocumenttype=='Governance and Procedure')", "tab": "VVQD", "locale": "en-US", "groupBy": [{"field": "@contentapprovaldate", "sortCriteria": "AlphaAscending"}], "searchHub": "AdvanceSearch"}`
        var favsearch = {
          favsearchPattern: query
        }
        var searchObj = {
          id: searchPattern.searchpatternId,
          name: searchPattern.patternName,
          query: favsearch
        }
  
        // console.log('searchObj-->' + JSON.stringify(searchObj));
  
        this.router.navigateByUrl('/', {
          state: {
            savedsearch: searchObj
          }
        })

      }catch(err){


        this.collectionNotAvailableInShareringFeature = "mycollectionsmessage";
        // this.title = "My Saved Search";
        this.title = CONFIG.CSNSHARE.SAVED_SEARCH_TITLE
        // this.body = "This Saved Search has been deleted by its owner and is no longer available.";
        this.body = CONFIG.CSNSHARE.SAVED_SEARCH_BODY;

        // this.router.navigateByUrl('/', {
        //   state: {
        //     // savedsearch: searchObj
        //   }
        // })

      }
      
    })
  }
}
